@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

/* outbound card config */
.outbound {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* wip pick list config */
.picklist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* warehouse job card config */
.warehousejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* complete pick list card config */
.complete {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* configuration for each process board (etc: outbound board, picklist board) */
.columncard {
  width: 6.5cm;
  margin-top: 1rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* configuration of the small cards inside of all the process board */
.rowcard {
  margin-bottom: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  background-position: center;
  animation: ripple 0.8s;
  -moz-animation: ripple 0.8s; /* Firefox */
  -webkit-animation: ripple 0.8s; /* Safari and Chrome */
  -o-animation: ripple 0.8s; /* Opera */
  animation-delay: 0.5s;
}

.rowcard:active {
  transform: translateY(2px);
}

@keyframes ripple {
  from {
    background-image: radial-gradient(circle, transparent 1%, #f4f4f4 1%);
    background-position: center;
    background-size: 100%;
    transform: translateY(2px);
  }
  to {
    background-color: white;
    background-size: 25000%;
  }
}

/* configuartion for card body */
.scr {
  background-color: #f7f7f7;
  overflow-y: auto;
}

/* configuration for the details of the card */
.progressinfo {
  display: flex;
}

/* config for day counter */
.daypassed {
  font-size: 11px;
  text-align: center;
}

/* config for product code, etc */
.infotext {
  margin-left: 20px;
  font-size: 12px;
}

/* config for number of lines */
.datetime {
  float: left;
  font-size: small;
}

.lineno {
  float: right;
  font-size: small;
}

/* badgecolor (light colors will have black font, dark colors will have white font) */
.badge-default {
  color: white;
  background-color: #ff0000;
}

.badge-green {
  color: white;
  background-color: #008000;
}

.badge-lime {
  color: white;
  background-color: #00ff00;
}
.badge-yellow {
  color: black;
  background-color: #ffff00;
}

.badge-gold {
  color: black;
  background-color: #ffd700;
}
.badge-orange {
  color: black;
  background-color: #ffa500;
}
.badge-pink {
  color: black;
  background-color: #ffc0cb;
}
