.wipslsord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  .wipoutbord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .wipinvdoc {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .completeloadlist {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  