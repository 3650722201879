@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* outbound card config */
.outbound {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* wip pick list config */
.picklist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* warehouse job card config */
.warehousejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* complete pick list card config */
.complete {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

/* configuration for each process board (etc: outbound board, picklist board) */
.columncard {
  width: 6.5cm;
  margin-top: 1rem;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* configuration of the small cards inside of all the process board */
.rowcard {
  margin-bottom: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  background-position: center;
  animation: ripple 0.8s;
  -moz-animation: ripple 0.8s; /* Firefox */
  -webkit-animation: ripple 0.8s; /* Safari and Chrome */
  -o-animation: ripple 0.8s; /* Opera */
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.rowcard:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

@-webkit-keyframes ripple {
  from {
    background-image: radial-gradient(circle, transparent 1%, #f4f4f4 1%);
    background-position: center;
    background-size: 100%;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  to {
    background-color: white;
    background-size: 25000%;
  }
}

@keyframes ripple {
  from {
    background-image: radial-gradient(circle, transparent 1%, #f4f4f4 1%);
    background-position: center;
    background-size: 100%;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  to {
    background-color: white;
    background-size: 25000%;
  }
}

/* configuartion for card body */
.scr {
  background-color: #f7f7f7;
  overflow-y: auto;
}

/* configuration for the details of the card */
.progressinfo {
  display: flex;
}

/* config for day counter */
.daypassed {
  font-size: 11px;
  text-align: center;
}

/* config for product code, etc */
.infotext {
  margin-left: 20px;
  font-size: 12px;
}

/* config for number of lines */
.datetime {
  float: left;
  font-size: small;
}

.lineno {
  float: right;
  font-size: small;
}

/* badgecolor (light colors will have black font, dark colors will have white font) */
.badge-default {
  color: white;
  background-color: #ff0000;
}

.badge-green {
  color: white;
  background-color: #008000;
}

.badge-lime {
  color: white;
  background-color: #00ff00;
}
.badge-yellow {
  color: black;
  background-color: #ffff00;
}

.badge-gold {
  color: black;
  background-color: #ffd700;
}
.badge-orange {
  color: black;
  background-color: #ffa500;
}
.badge-pink {
  color: black;
  background-color: #ffc0cb;
}

/* font settings for modal in the dashboard*/
.modalinfo-row {
  border-bottom: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalinfo {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
}

.modalinfo-details {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
  /*
  font-style: italic;
  font-weight: bold;
  text-align: center;
  */
}

.pick-list-dialog .ant-modal-header {
  background-image: radial-gradient(circle at top left, #ffa500, #ffc0cb);
}

.pick-list-dialog .ant-modal-title {
  color: black;
  text-align: center;
  font-size: large;
}

.wippicklist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.wiploadlist {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.wipwhsejobb {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

.completeloadlistt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
  font-weight: 500;
}

/* font settings for modal in the dashboard*/
.modalinfo-row {
  border-bottom: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalinfo {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
}

.modalinfo-details {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
  /*
  font-style: italic;
  font-weight: bold;
  text-align: center;
  */
}

.load-list-dialog .ant-modal-header {
  background-image: radial-gradient(circle at top left, #ffa500, #ffc0cb);
}

.load-list-dialog .ant-modal-title {
  color: black;
  text-align: center;
  font-size: large;
}

.wipslsord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  .wipoutbord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .wipinvdoc {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .completeloadlist {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
/* font settings for modal in the dashboard*/
.modalinfo-row {
  border-bottom: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalinfo {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
}

.modalinfo-details {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
  /*
  font-style: italic;
  font-weight: bold;
  text-align: center;
  */
}

.sls-ord-dialog .ant-modal-header {
  background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
}

.sls-ord-dialog .ant-modal-title {
  color: black;
  text-align: center;
  font-size: large;
}

.wipslsord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  .wipoutbord {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .wipinvdoc {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
  .completeloadlist {
    text-align: center;
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 500;
  }
  
/* font settings for modal in the dashboard*/
.modalinfo-row {
  border-bottom: 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modalinfo {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
}

.modalinfo-details {
  font-family: 'Roboto', sans-serif;
  font-size: 13.8px;
  /*
  font-style: italic;
  font-weight: bold;
  text-align: center;
  */
}

.sls-ord-dialog .ant-modal-header {
  background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
}

.sls-ord-dialog .ant-modal-title {
  color: black;
  text-align: center;
  font-size: large;
}

.wipinbord {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipgdsrcpt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipwhsejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.completegdsreceipt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipinbord {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipgdsrcpt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipwhsejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.completegdsreceipt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipinbord {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipgdsrcpt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipwhsejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.completegdsreceipt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipinbord {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipgdsrcpt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipwhsejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.completegdsreceipt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.sls-ord-summary .ant-statistic-title {
    font-size: small;
}

.summary-card {
    width: 8.5cm;
    margin-top: 1rem;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
}

.sls-ord-header, .lsp-sls-ord-header {
    background-image: radial-gradient(circle at top left, #9ea7fc, #6eb4f7);
    font-weight: 600;
}

.pick-list-header {
    background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
    font-weight: 600;
}

.load-list-header {
    background-image: radial-gradient(circle at top left, #ff735a, #ffa999);
    font-weight: 600;
}

.custom_tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 21;
  align-items: center;
  justify-items: center;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border: solid 1px black;
  background-color: white;
  border-radius: 4px;
}

.custom_tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 21;
  align-items: center;
  justify-items: center;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border: solid 1px black;
  background-color: white;
  border-radius: 4px;
}

.custom_tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 21;
  align-items: center;
  justify-items: center;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border: solid 1px black;
  background-color: white;
  border-radius: 4px;
}

.custom_tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 21;
  align-items: center;
  justify-items: center;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border: solid 1px black;
  background-color: white;
  border-radius: 4px;
}

tr.info-row {
  background-color: #e6f7ff;
  color: #000000;
}

tr.info-row-1 {
  background-color: #d0e0ff;
  color: #000000;
}

tr.success-row {
  background-color: #f6ffed;
  color: #000000;
}

tr.success-row-1 {
  background-color: #ddffee;
  color: #000000;
}

tr.warning-row {
  background-color: #fffbe6;
  color: #000000;
}

tr.warning-row-1 {
  background-color: #fffacd;
  color: #000000;
}

tr.counting-row {
  background-color: #fffacd;
  color: #000000;
}

tr.counted-row {
  background-color: #fffef4;
  color: #000000;
}

tr.error-row {
  background-color: #ff8077;
  color: #000000;
}

tr.error-row-1 {
  background-color: #ff685e;
  color: #000000;
}

tr.hex-D0E0FF {
  background-color: #D0E0FF;
  color: #000000;
}

tr.hex-DDFFEE {
  background-color: #DDFFEE;
  color: #000000;
}

tr.hex-FFFACD {
  background-color: #FFFACD;
  color: #000000;
}

tr.hex-FECBCF {
  background-color: #FECBCF;
  color: #000000;
}

tr.hex-7FFFD4 {
  background-color: #7FFFD4;
  color: #000000;
}

tr.hex-F5F5DC {
  background-color: #F5F5DC;
  color: #000000;
}

tr.hex-F7E7CE {
  background-color: #F7E7CE;
  color: #000000;
}

tr.hex-EDC9AF {
  background-color: #EDC9AF;
  color: #000000;
}

tr.hex-00FFFF {
  background-color: #00FFFF;
  color: #000000;
}

tr.hex-E0B0FF {
  background-color: #E0B0FF;
  color: #000000;
}

tr.hex-FFE5B4 {
  background-color: #FFE5B4;
  color: #000000;
}

tr.hex-CCCCFF {
  background-color: #CCCCFF;
  color: #000000;
}

tr.hex-C0C0C0 {
  background-color: #C0C0C0;
  color: #000000;
}

tr.hex-D2B48C {
  background-color: #D2B48C;
  color: #000000;
}

tr.hex-D2B4ED {
  background-color: #D2B4ED;
  color: #000000;
}

tr.hex-F0EFB0 {
  background-color: #F0EFB0;
  color: #000000;
}
