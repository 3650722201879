@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.wipinbord {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipgdsrcpt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.wipwhsejob {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}

.completegdsreceipt {
  text-align: center;
  background-image: radial-gradient(circle at top left, #ffb74e, #ff9985);
  font-weight: 500;
}
